import apiClient from './index'

const getOrderPath = ({ orderId }) => {
  return orderId ? `api/orders/${orderId}/` : 'api/orders/'
}

const getVadainOrderPath = ({ orderId }) => {
  return orderId ? `api/vadain-orders/${orderId}/` : 'api/vadain-orders/'
}

const getArticleOrderPath = ({ orderId, articleType, orderNumber }) => {
  let path = `${getOrderPath({ orderId })}articles/`
  if (articleType) {
    path += `${articleType}/`
  }
  if (orderNumber) {
    path += `${orderNumber}/`
  }
  return path
}

const getArticleDuplicatePath = ({ orderId, orderNumber }) => {
  return `${getOrderPath({ orderId })}articles/${orderNumber}/duplicate/`
}
const getArticleMovePath = ({ orderId, orderNumber }) => {
  return `${getOrderPath({ orderId })}articles/${orderNumber}/move/`
}

export default {
  // create
  async createArticleOrder(urlParams, data) {
    return await apiClient.post(getArticleOrderPath(urlParams), data)
  },
  async createOrder(data) {
    return await apiClient.post(getOrderPath({}), data)
  },
  // retrieve
  async getArticleOrder(urlParams) {
    return await apiClient.get(getArticleOrderPath(urlParams))
  },
  async getOrder(urlParams, params) {
    return await apiClient.get(getOrderPath(urlParams), { params })
  },
  async getOrders(params) {
    return await apiClient.get(getOrderPath({}), { params })
  },
  // retrieve - order tracking
  async getVadainOrder(urlParams, params) {
    return await apiClient.get(getVadainOrderPath(urlParams), { params })
  },
  async getVadainOrders(params) {
    return await apiClient.get(getVadainOrderPath({}), { params })
  },
  // update
  async updateArticleOrder(urlParams, data) {
    return await apiClient.put(getArticleOrderPath(urlParams), data)
  },
  async updateOrder(urlParams, data) {
    return await apiClient.put(getOrderPath(urlParams), data)
  },
  async partialUpdateOrder(urlParams, data) {
    return await apiClient.patch(getOrderPath(urlParams), data)
  },
  async updateOrderVadainMainFabric(urlParams, data) {
    return await apiClient.post(
      `${getOrderPath(urlParams)}update-vadain-main-fabric/`,
      data
    )
  },
  // delete
  async deleteArticleOrder(urlParams) {
    return await apiClient.delete(getArticleOrderPath(urlParams))
  },
  async deleteOrder(urlParams) {
    return await apiClient.delete(getOrderPath(urlParams))
  },
  // confirm
  async confirmOrder(urlParams) {
    return await apiClient.post(`${getOrderPath(urlParams)}confirm/`)
  },
  // duplicate
  async duplicateArticleOrder(urlParams) {
    return await apiClient.post(getArticleDuplicatePath(urlParams))
  },
  // move
  async moveArticleOrder(urlParams, data) {
    return await apiClient.post(getArticleMovePath(urlParams), data)
  },
  // split offer
  async splitOrder(urlParams, data) {
    return await apiClient.post(`${getOrderPath(urlParams)}split/`, data)
  },
  // reports
  async generateReport(orderId, reportType) {
    return await apiClient.get(`${getOrderPath({ orderId })}${reportType}/`, {
      responseType: 'blob'
    })
  },
  // match strokes
  async matchStrokes(urlParams, data) {
    return await apiClient.post(`${getOrderPath(urlParams)}combine-strokes/`, data)
  },
  // create a set
  async createSet(urlParams, data) {
    return await apiClient.post(`${getOrderPath(urlParams)}curtain-set/`, data)
  },
  // undo a set
  async removeSet(urlParams, data) {
    return await apiClient.post(`${getOrderPath(urlParams)}undo-curtain-set/`, data)
  },
  async unmatchStrokes(urlParams, data) {
    return await apiClient.post(`${getOrderPath(urlParams)}undo-combine-strokes/`, data)
  },
  async updateExternal(data) {
    return await apiClient.post(`${getOrderPath({})}update-external/`, data)
  }
}
