import orderEndpoints from '@/api/orders'
import {
  CREATED,
  CURTAIN_TYPES,
  ELECTRIC_RAIL_BASIC,
  ELECTRIC_RAIL_DECORATIVE_R35MM,
  ELECTRIC_RAIL_DECORATIVE_S32MM,
  EXISTING_CUSTOMER_FABRICS,
  FOLD_CURTAIN,
  OK,
  RAIL_ROD_PARTS_ARTICLE_TYPE,
  USER
} from '@/constants'
import i18n from '@/i18n'
import { cachedAxios } from '@/api'
import { Decimal } from 'decimal.js'
import { isEmpty, pickBy, mapValues, has } from 'lodash-es'

export const deepCopy = (obj) => {
  return Object(JSON.parse(JSON.stringify(obj)))
}

export const tryExceptAwait = async (func, payload) => {
  async function getTry() {
    if (payload) {
      return await func(...payload)
    } else {
      return await func()
    }
  }

  try {
    return await getTry()
  } catch (error) {
    if (!error) return {}
    return error
  }
}

export const capitalizeFirstLetter = (string) => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : null
}

export const multiplyDecimals = (decimal1, decimal2) => {
  return Decimal(decimal1).times(Decimal(decimal2)).toFixed(2)
}

export const scrollToTop = (y = 0) => {
  window.scrollTo({
    top: y,
    left: 0,
    behavior: 'smooth'
  })
}

// get image with fallback in case it does not exist.
// you can give a default image e.g. when it depends on a question that has
// not yet been answered.
export const requireAssetWithFallback = (imgSrc, altImgSrc = null) => {
  try {
    return require(`@/assets/${imgSrc}`)
  } catch {
    if (altImgSrc !== null) {
      return requireAssetWithFallback(altImgSrc)
    }
    return require(`@/assets/noimage.png`)
  }
}

export const hasEqualSize = (size1, size2) => {
  // check if two size definition objects with
  // min, max and increment are equal
  const sizeObject1 = isEmpty(size1) ? {} : size1
  const sizeObject2 = isEmpty(size2) ? {} : size2
  return (
    sizeObject1.min === sizeObject2.min &&
    sizeObject1.max === sizeObject2.max &&
    sizeObject1.increment === sizeObject2.increment
  )
}

export const getOrders = async (orderStatus = 'draft', debtorId = null) => {
  const params = {
    status: orderStatus
  }
  if (debtorId) {
    params.debtor_id = debtorId
  }
  const { data, status } = await tryExceptAwait(orderEndpoints.getOrders, [params])
  return status === OK ? data.results : null
}

export const createArticleOrder = async (orderId, articleType, articleData) => {
  return await tryExceptAwait(orderEndpoints.createArticleOrder, [
    { orderId, articleType },
    articleData
  ])
}

export const updateArticleOrder = async (
  orderId,
  orderNumber,
  articleType,
  articleData
) => {
  return await tryExceptAwait(orderEndpoints.updateArticleOrder, [
    { orderId, orderNumber, articleType },
    articleData
  ])
}

export const createDraftOrder = async (orderData = null) => {
  const { data, status } = await tryExceptAwait(orderEndpoints.createOrder, [orderData])
  return status === CREATED ? data.id : null
}

export const partialUpdateOrder = async (orderId, orderData) => {
  const { data, status } = await tryExceptAwait(orderEndpoints.partialUpdateOrder, [
    { orderId },
    orderData
  ])
  return { data, status }
}

export const loadArticleOrderData = async ({ orderId, orderNumber }) => {
  const { data, status } = await tryExceptAwait(orderEndpoints.getArticleOrder, [
    { orderId, orderNumber }
  ])
  return { data, status }
}

export const timeStampToDate = (timeStamp, locale) => {
  try {
    if (!timeStamp) {
      return ''
    }
    const date = new Date(timeStamp)
    return new Intl.DateTimeFormat(locale || i18n.locale, {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    }).format(date)
  } catch (e) {
    console.log('Invalid datetime', e)
  }
}

export const userGroupTranslationWithFallback = (t, te, key, userGroup) => {
  /* Return a user group specific translation with fallback.
   * Normal translations can be defined like
   * { 'translation.key1': 'value1' }
   * Group specific translations can be defined like
   * { 'translation.key1.group': 'value1' }.
   * If you are using group specific translations you need to
   * add a "Default" group translation as fallback.
   */
  const userGroupKey = `${key}.${userGroup}`
  const defaultUserGroupKey = `${key}.Default`
  if (te(userGroupKey)) {
    // user group translation
    return t(userGroupKey)
  } else if (te(defaultUserGroupKey)) {
    // fallback 1: "Default" user group translation
    return t(defaultUserGroupKey)
  }
  // fallback 2: normal translation
  return t(key)
}

export const getArticleEditRoute = (articleType, edit = true) => {
  if (articleType === FOLD_CURTAIN) {
    return edit ? 'foldcurtain-edit' : 'foldcurtain'
  } else if (articleType === RAIL_ROD_PARTS_ARTICLE_TYPE) {
    return edit ? 'railrodparts-edit' : 'railrodparts'
  } else if (CURTAIN_TYPES.includes(articleType)) {
    return edit ? 'curtain-edit' : 'curtain'
  } else {
    return edit ? 'railrod-edit' : 'railrod'
  }
}

export const cleanFabricUuid = (fabricUuid) => {
  // remove alphanumeric characters except - from fabric uuids
  return fabricUuid.replace(/[^0-9a-zA-Z_-]/g, '')
}

export const hasCustomerFabric = (article) => {
  return (
    (article.main_fabric_uuid || '').includes(USER) ||
    (article.lining_fabric_uuid || '').includes(USER)
  )
}

export const getBaseRailRodType = (railRodType) => {
  /* Get the base railRodType given the current railRodType value.
   * This is mainly used for electric rails which are based on non
   * electric rail models. For example, electric rail decorative
   * would map to a base model 'decorativerail'.
   */
  const baseElectricRailTypeMapping = {
    [ELECTRIC_RAIL_BASIC]: 'trackrail',
    [ELECTRIC_RAIL_DECORATIVE_R35MM]: 'decorativerail',
    [ELECTRIC_RAIL_DECORATIVE_S32MM]: 'decorativerail'
  }
  return baseElectricRailTypeMapping[railRodType] || railRodType
}

export const getBasicRailType = (railRodType) => {
  const baseElectricRailTypeMapping = {
    [ELECTRIC_RAIL_DECORATIVE_R35MM]: 'round35mm',
    [ELECTRIC_RAIL_DECORATIVE_S32MM]: 'square30mm'
  }
  return baseElectricRailTypeMapping[railRodType]
}

export const getFabricTitle = (fabric, fabricOption) => {
  if (!fabric.name) {
    return ''
  }
  return fabricOption === EXISTING_CUSTOMER_FABRICS
    ? `${fabric.name} ${fabric.color}`
    : fabric.name
}

export const setFabricImage = async (fabric, fabricOption) => {
  let usedImage = fabric.image
  let usedImages = [usedImage]
  if (fabricOption === EXISTING_CUSTOMER_FABRICS) {
    usedImage =
      fabric.image && !fabric.image.endsWith('NOIMAGE.JPG')
        ? customerFabricImagePath(fabric.image)
        : require(`@/assets/noimage.png`)
    usedImages = [usedImage]
  }
  if (fabric.rendered_image) {
    // get rendered image HEAD response to get image content type
    try {
      const getRenderedImage = async () => await cachedAxios.head(fabric.rendered_image)
      const response = await tryExceptAwait(getRenderedImage)
      if (response?.headers?.['content-type'] !== 'image/gif') {
        // we have a valid image (GIF content type means "no image" response)
        usedImage = fabric.rendered_image
        usedImages = fabric.rendered_image_details
      }
    } catch (e) {
      console.log('error fetching fabrics', e)
    }
  }
  fabric.image = usedImage
  fabric.images = usedImages
}

export const customerFabricImagePath = (url) => {
  // append api host to relative urls that do not start with the api host
  return url.startsWith('/') &&
    !url.startsWith(process.env.VUE_APP_CURTAIN_CONFIG_API_HOST)
    ? `${process.env.VUE_APP_CURTAIN_CONFIG_API_HOST}${url}`
    : url
}

export const getArticlePricesFields = (articleData) => {
  // from article's payload get all fields related to prices
  return pickBy(articleData, (val, key) => key.includes('price'))
}

const translateSizeDefinitionValidation = (item) => {
  if (hasValidationRules(item)) {
    const max = item.max
    const min = item.min
    return `required|double:1|min_value:${min}|max_value:${max}`
  }
  return 'required|double:1'
}
const hasValidationRules = (templateDefinition) => {
  return !!(templateDefinition.min || templateDefinition.max)
}
const mapProperties = (template, predict, extend) => {
  return mapValues(pickBy(template, predict), extend)
}
// main method
const mapTemplateDefinitions = (template) => {
  const sizeDefinitionPredict = (value, key) => {
    return value && key.includes('definition') && has(value, 'increment')
  }
  const sizeDefinitionExtend = (value, key) => {
    return {
      ...value,
      template_key: key,
      schemaExtension: {
        type: 'text',
        validation: translateSizeDefinitionValidation(value),
        id: key.replace('_definition', '')
      }
    }
  }

  const booleanDefinitionPredict = (value, key) => {
    return value && key.includes('definition') && has(value, 'hidden')
  }
  const booleanDefinitionExtend = (value, key) => {
    return {
      ...value,
      template_key: key,
      schemaExtension: {
        type: 'checkbox',
        id: key.replace('_definition', '')
      }
    }
  }

  return {
    sizeDefinitions: mapProperties(template, sizeDefinitionPredict, sizeDefinitionExtend),
    booleanDefinitions: mapProperties(
      template,
      booleanDefinitionPredict,
      booleanDefinitionExtend
    )
  }
}
// This method returns extended template.
// Logic: for all template properties which has 'definition' keyword
// it picks sizeDefinitions and booleanDefinitions
// and adds some extra object `schemaExtension` with a payload
// to mix it into flow question
export const extendTemplate = (template) => {
  const { sizeDefinitions, booleanDefinitions } = mapTemplateDefinitions(template)
  return {
    ...template,
    ...sizeDefinitions,
    ...booleanDefinitions
  }
}
