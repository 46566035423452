import { omit, cloneDeep } from 'lodash-es'
import {
  getOrders,
  createArticleOrder,
  updateArticleOrder,
  createDraftOrder
} from '@/utils'

import topbar from './topbar'
import pullrod from './pullrod'
import railrodparts from './railrodparts'
import { RAIL_TYPES, CREATED, OK, BAD_REQUEST, FORBIDDEN } from '@/constants'

const getDefaultState = (
  priceFetchStatus = true,
  orderId = undefined,
  priceType = 'price'
) => {
  return {
    // order id of the order being edited
    orderId,
    // id of the curtain article order being edited
    curtainOrderNumber: undefined,
    // id of the rail rod article order being edited
    railRodOrderNumber: undefined,
    // the room in which the curtain/accessory should be placed
    room: '',
    // main fabric for a curtain / sample
    fabric: {},
    // lining fabric for a curtain
    lining: {},
    // can store multiple sample fabrics
    fabrics: [],
    // type of the curtain if applicable
    curtainType: undefined,
    // type of the fold curtain if applicable
    foldType: undefined,
    // type of the rail/rod if applicable
    railRodType: '',
    // boolean, should the lining step be added?
    withLining: false,
    // boolean, should the memo step be added?
    withCurtainMemo: false,
    // boolean, should the memo step be added on railrod?
    withRailRodMemo: false,
    // boolean, should the rail/rod step be added?
    withRailRod: false,
    // railRod just saves a string "rod" for ring curtain,
    // or "rail" for other curtains
    railRod: 'rail',
    // parameters which can be used to calculate a curtain
    // price or save order data
    curtainParameters: {},
    // the price object returned by the curtain price endpoints
    curtainPriceData: {},
    // the amount of curtain and/or rail rod articles that
    // should be created on completing the flow
    quantity: 1,
    // the flow of the curtain that should be mapped from
    // order data and loaded into the CurtainFlow component
    curtainFlow: {},
    // the template containing information about the possible
    // options for a certain curtain type
    curtainTemplate: {},
    // the template containing information about the possible
    // options for a certain rail rod type
    railRodTemplate: {},
    // the flow of the rail rod that should be mapped from
    // order data and loaded into the RailRodFlow component
    railRodFlow: {},
    // parameters which can be used to calculate a rail rod
    // price or save order data
    railRodParameters: {},
    // the price object returned by the rail rod price endpoints
    railRodPriceData: {},
    // the price type to show, default - sale price
    priceType,
    // for checking progress on determinate processes
    progress: 0,
    // do price requests automatically is true
    priceFetchStatus,
    // api error message when creating/updating order
    errorMessage: {},
    // whether prices should be fetched or not, for example when we need
    // to respect historical price data and do not want to load prices
    // based on current parameters, this variable should be set to false
    hasFetchPrices: true
  }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
  getOrderId(state) {
    return state.orderId
  },
  getCurtainOrderNumber(state) {
    return state.curtainOrderNumber
  },
  getRailRodOrderNumber(state) {
    return state.railRodOrderNumber
  },
  getRoom(state) {
    return state.room
  },
  getFabric(state) {
    return state.fabric
  },
  getLining(state) {
    return state.lining
  },
  getFabrics(state) {
    return state.fabrics
  },
  getCurtainType(state) {
    return state.curtainType
  },
  getFoldType(state) {
    return state.foldType
  },
  getRailRodType(state) {
    return state.railRodType
  },
  getRailRodCurveTypeStateWidget(state) {
    return { curveType: state.railRodCurveType }
  },
  getWithLining(state) {
    return state.withLining
  },
  getWithCurtainMemo(state) {
    return state.withCurtainMemo
  },
  getWithRailRodMemo(state) {
    return state.withRailRodMemo
  },
  getWithRailRod(state) {
    return state.withRailRod
  },
  getRailRod(state) {
    return state.railRod
  },
  getCurtainParameters(state) {
    return state.curtainParameters
  },
  getCurtainPriceData(state) {
    return state.curtainPriceData
  },
  getQuantity(state) {
    return state.quantity
  },
  getCurtainFlow(state) {
    return state.curtainFlow
  },
  getCurtainTemplate(state) {
    return state.curtainTemplate
  },
  getRailRodTemplate(state) {
    return state.railRodTemplate
  },
  getRailRodFlow(state) {
    return state.railRodFlow
  },
  getRailRodParameters(state) {
    return state.railRodParameters
  },
  getRailRodPriceData(state) {
    return state.railRodPriceData
  },
  getPriceType(state) {
    return state.priceType
  },
  getProgress(state) {
    return state.progress
  },
  getPriceFetchStatus(state) {
    return state.priceFetchStatus
  },
  getErrorMessage(state) {
    return state.errorMessage
  },
  getHasFetchPrices(state) {
    return state.hasFetchPrices
  }
}

// mutations
const mutations = {
  setOrderId(state, orderId) {
    state.orderId = orderId
  },
  setCurtainOrderNumber(state, curtainOrderNumber) {
    state.curtainOrderNumber = curtainOrderNumber
  },
  setRailRodOrderNumber(state, railRodOrderNumber) {
    state.railRodOrderNumber = railRodOrderNumber
  },
  setRoom(state, room) {
    state.room = room
    state.curtainParameters = {
      ...state.curtainParameters,
      room
    }
    state.railRodParameters = {
      ...state.railRodParameters,
      room
    }
  },
  addFabric(state, fabricDetails) {
    // add fabrics to multiselect fabric picker
    state.fabrics = [...state.fabrics, fabricDetails]
  },
  deleteFabric(state, fabricUuid) {
    // remove fabrics from multiselect fabric picker
    state.fabrics = state.fabrics.filter(
      (selectedFabric) => selectedFabric.fabric_uuid !== fabricUuid
    )
  },
  setFabric(state, fabricDetails) {
    // set fabric for single select fabric picker
    state.fabric = fabricDetails
  },
  setLining(state, fabricDetails) {
    // set lining for single select fabric picker
    state.lining = fabricDetails
  },
  resetLining(state) {
    state.lining = {}
    state.curtainParameters = omit(state.curtainParameters, ['lining_fabric'])
  },
  setCurtainType(state, curtainType) {
    state.curtainType = curtainType
  },
  setFoldType(state, foldType) {
    state.foldType = foldType
    state.curtainParameters = {
      ...state.curtainParameters,
      fold_type: foldType
    }
  },
  setRailRodType(state, railRodType) {
    state.railRodType = railRodType
  },
  setWithLining(state, withLining) {
    state.withLining = withLining
  },
  setWithCurtainMemo(state, withMemo) {
    state.withCurtainMemo = withMemo
  },
  setWithRailRodMemo(state, withMemo) {
    state.withRailRodMemo = withMemo
  },
  setWithRailRod(state, withRailRod) {
    state.withRailRod = withRailRod
  },
  setRailRod(state, railRod) {
    state.railRod = railRod
  },
  setCurtainParameters(state, curtainParameters) {
    state.curtainParameters = curtainParameters
  },
  addCurtainParameter(state, curtainParameter) {
    state.curtainParameters = {
      ...state.curtainParameters,
      // add cloneDeep when using inline form fields with nested values
      ...cloneDeep(curtainParameter)
    }
  },
  removeCurtainParameters(state, curtainParameters) {
    state.curtainParameters = omit(state.curtainParameters, curtainParameters)
  },
  addCurtainTemplateParameter(state, curtainTemplateParameter) {
    state.curtainTemplate = {
      ...state.curtainTemplate,
      ...curtainTemplateParameter
    }
  },
  setCurtainPriceData(state, curtainPriceData) {
    state.curtainPriceData = curtainPriceData
  },
  setQuantity(state, quantity) {
    state.quantity = quantity
  },
  setCurtainFlow(state, curtainFlow) {
    state.curtainFlow = curtainFlow
  },
  setCurtainTemplate(state, curtainTemplate) {
    state.curtainTemplate = curtainTemplate
  },
  setRailRodTemplate(state, railRodTemplate) {
    state.railRodTemplate = railRodTemplate
  },
  setRailRodFlow(state, railRodFlow) {
    state.railRodFlow = railRodFlow
  },
  setRailRodParameters(state, railRodParameters) {
    state.railRodParameters = railRodParameters
  },
  addRailRodParameter(state, railRodParameter) {
    state.railRodParameters = {
      ...state.railRodParameters,
      ...railRodParameter
    }
  },
  removeRailRodParameters(state, railRodParameters) {
    state.railRodParameters = omit(state.railRodParameters, railRodParameters)
  },
  addRailRodTemplateParameter(state, railRodTemplateParameter) {
    state.railRodTemplate = {
      ...state.railRodTemplate,
      ...railRodTemplateParameter
    }
  },
  setRailRodPriceData(state, railRodPriceData) {
    state.railRodPriceData = railRodPriceData
  },
  resetState(state) {
    Object.assign(
      state,
      getDefaultState(state.priceFetchStatus, state.orderId, state.priceType)
    )
  },
  setPriceType(state, priceType) {
    state.priceType = priceType
  },
  setProgress(state, progress) {
    state.progress = progress
  },
  enablePriceFetch(state) {
    state.priceFetchStatus = true
  },
  disablePriceFetch(state) {
    state.priceFetchStatus = false
  },
  setErrorMessage(state, errorMessage) {
    state.errorMessage = { ...errorMessage }
  },
  setHasFetchPrices(state, hasFetchPrices) {
    state.hasFetchPrices = hasFetchPrices
  }
}

// actions
const actions = {
  setRoom({ commit }, room) {
    commit('setRoom', room)
  },
  setCurtainType({ commit }, curtainType) {
    commit('setCurtainType', curtainType)
  },
  setFoldType({ commit }, foldType) {
    commit('setFoldType', foldType)
  },
  setRailRodType({ commit }, railRodType) {
    commit(
      'setRailRod',
      RAIL_TYPES.includes(railRodType) || !railRodType ? 'rail' : 'rod'
    )
    commit('setRailRodType', railRodType)
  },
  setRailRod({ commit }, railRod) {
    commit('setRailRod', railRod)
  },
  setWithLining({ commit }, withLining) {
    commit('setWithLining', withLining)
  },
  setWithCurtainMemo({ commit }, withMemo) {
    commit('setWithCurtainMemo', withMemo)
  },
  setWithRailRodMemo({ commit }, withMemo) {
    commit('setWithRailRodMemo', withMemo)
  },
  setWithRailRod({ commit }, withRailRod) {
    commit('setWithRailRod', withRailRod)
  },
  addFabric({ commit }, { fabricDetails }) {
    commit('addFabric', fabricDetails)
  },
  deleteFabric({ commit }, { fabricUuid }) {
    commit('deleteFabric', fabricUuid)
  },
  setFabric({ commit }, { fabricDetails }) {
    commit('setFabric', fabricDetails)
  },
  setLining({ commit }, { fabricDetails }) {
    commit('setLining', fabricDetails)
  },
  resetFabric({ commit }) {
    commit('setFabric', {})
  },
  resetLining({ commit }) {
    commit('resetLining')
  },
  addCurtainParameter({ commit }, curtainParameter) {
    commit('addCurtainParameter', curtainParameter)
  },
  removeCurtainParameters({ commit }, curtainParameters) {
    commit('removeCurtainParameters', curtainParameters)
  },
  addCurtainTemplateParameter({ commit }, curtainTemplateParameter) {
    commit('addCurtainTemplateParameter', curtainTemplateParameter)
  },
  setCurtainParameters({ commit }, curtainParameters) {
    commit('setCurtainParameters', curtainParameters)
  },
  setCurtainPriceData({ commit }, curtainPriceData) {
    commit('setCurtainPriceData', curtainPriceData)
  },
  setQuantity({ commit }, quantity) {
    commit('setQuantity', quantity)
  },
  setCurtainFlow({ commit }, curtainFlow) {
    commit('setCurtainFlow', curtainFlow)
  },
  setCurtainTemplate({ commit }, curtainTemplate) {
    commit('setCurtainTemplate', curtainTemplate)
  },
  setRailRodTemplate({ commit }, railRodTemplate) {
    commit('setRailRodTemplate', railRodTemplate)
  },
  setRailRodFlow({ commit }, railRodFlow) {
    commit('setRailRodFlow', railRodFlow)
  },
  addRailRodParameter({ commit }, railRodParameter) {
    commit('addRailRodParameter', railRodParameter)
  },
  removeRailRodParameters({ commit }, railRodParameters) {
    commit('removeRailRodParameters', railRodParameters)
  },
  addRailRodTemplateParameter({ commit }, railRodTemplateParameter) {
    commit('addRailRodTemplateParameter', railRodTemplateParameter)
  },
  setRailRodParameters({ commit }, railRodParameters) {
    commit('setRailRodParameters', railRodParameters)
  },
  setRailRodPriceData({ commit }, railRodPriceData) {
    commit('setRailRodPriceData', railRodPriceData)
  },
  resetOrderNumbers({ commit }, keepOrderId = false) {
    if (!keepOrderId) {
      commit('setOrderId', undefined)
    }
    commit('setCurtainOrderNumber', undefined)
    commit('setRailRodOrderNumber', undefined)
    commit('order/pullrod/setPullRodOrderNumber', undefined, { root: true })
    commit('order/railrodparts/setRailRodPartsOrderNumber', undefined, {
      root: true
    })
  },
  async resetCurtain({ state, commit }) {
    commit('setCurtainTemplate', {})
    commit('setCurtainParameters', {
      room: state.room,
      main_fabric: state.fabric.fabric_uuid
    })
    commit('setCurtainPriceData', {})
    commit('setQuantity', 1)
    commit('setLining', {})
    commit('setCurtainType', undefined)
    commit('setFoldType', undefined)
    commit('setWithCurtainMemo', false)
    commit('setWithRailRodMemo', false)
    commit('setWithLining', false)
  },
  resetRailRodParameters({ state, commit }) {
    commit(
      'setRailRodParameters',
      Object.assign(
        { room: state.room },
        state.withRailRod && {
          with_runner: state.railRodParameters.with_runner
        },
        state.curtainType === 'wavecurtain' && {
          runner_type: state.railRodParameters.runner_type,
          runner_color: state.railRodParameters.runner_color
        },
        state.curtainOrderNumber && {
          curtain_order_number: state.curtainOrderNumber
        }
      )
    )
  },
  resetRailRod({ commit, dispatch }) {
    commit('setRailRodTemplate', {})
    commit('setRailRodFlow', {})
    dispatch('resetRailRodParameters')
    commit('setRailRodPriceData', {})
    commit('setQuantity', 1)
    commit('setRailRodType', undefined)
    commit('setWithRailRod', false)
  },
  async resetState({ commit, dispatch }) {
    await dispatch('order/pullrod/resetPullRod', null, { root: true })
    await dispatch('order/railrodparts/resetState', null, { root: true })
    commit('resetState')
  },
  setPriceType({ commit }, priceType) {
    commit('setPriceType', priceType)
  },
  setProgress({ commit }, progress) {
    commit('setProgress', progress)
  },
  setDefaultPriceType({ commit, rootGetters }) {
    const priceType = rootGetters['user/getPriceType']
    commit('setPriceType', priceType)
  },
  setOrderId({ commit }, orderId) {
    commit('setOrderId', orderId)
  },
  setHasFetchPrices({ commit }, hasFetchPrices) {
    commit('setHasFetchPrices', hasFetchPrices)
  },
  async createOrUpdateCurtainOrder({ state, commit, dispatch }) {
    const orderId = state.orderId || (await dispatch('getOrCreateDraftOrder'))
    const { data, status } = state.curtainOrderNumber
      ? // update existing curtain order
        await updateArticleOrder(
          orderId,
          state.curtainOrderNumber,
          state.curtainType,
          state.curtainParameters
        )
      : // create new curtain order
        await createArticleOrder(orderId, state.curtainType, state.curtainParameters)

    if (status === OK || status === CREATED) {
      commit('setErrorMessage', {})
      commit('addRailRodParameter', { curtain_order_number: data.order_number })
      commit(
        'order/pullrod/addPullRodParameter',
        { curtain_order_number: data.order_number },
        { root: true }
      )
      commit('setCurtainOrderNumber', data.order_number)
    } else if (status >= BAD_REQUEST && status !== FORBIDDEN) {
      commit('setErrorMessage', data)
      console.error('There was an error while creating or updating a curtain order')
    }
  },
  async createOrUpdateRailRodOrder({ state, commit, dispatch }) {
    const orderId = state.orderId || (await dispatch('getOrCreateDraftOrder'))
    const { data, status } = state.railRodOrderNumber
      ? // update existing rail rod order
        await updateArticleOrder(
          orderId,
          state.railRodOrderNumber,
          state.railRodType,
          state.railRodParameters
        )
      : // create new rail rod order
        await createArticleOrder(orderId, state.railRodType, state.railRodParameters)

    if (status === OK || status === CREATED) {
      commit('setErrorMessage', {})
      commit('setRailRodOrderNumber', data.order_number)
    } else if (status >= BAD_REQUEST && status !== FORBIDDEN) {
      commit('setErrorMessage', data)
      console.error('There was an error while creating or updating a rail rod order')
    }
  },
  async loadAndSetLining({ commit, dispatch, rootGetters }, fabricUuid) {
    await dispatch(
      'linings/loadFabricDetails',
      { urlParams: { fabricUuid } },
      { root: true }
    )
    const fabricDetails = rootGetters['linings/getFabricDetails']
    if (fabricDetails) {
      commit('setLining', rootGetters['linings/getFabricDetails'][fabricUuid])
    }
  },
  async loadAndSetFabric(
    { commit, dispatch, rootGetters },
    { fabricUuid, debtorId = null }
  ) {
    await dispatch(
      'fabrics/loadFabricDetails',
      { urlParams: { fabricUuid, debtorId } },
      { root: true }
    )
    const fabricDetails = rootGetters['fabrics/getFabricDetails']
    if (fabricDetails) {
      commit('setFabric', rootGetters['fabrics/getFabricDetails'][fabricUuid])
    }
  },
  // Retrieve
  // get methods can be used for the edit flow, where we need to load
  // order data into the vuex state initially
  async getOrCreateDraftOrder({ commit, rootGetters }) {
    // normal users have one draft order at a time
    // service account users will get a draft order for each servicing debtor
    const isServiceAccount = rootGetters['user/isServiceAccount']
    const debtor = rootGetters['user/getDebtor'] || {}
    const servicingDebtor = rootGetters['user/getServicingDebtor'] || {}
    // fetch draft orders
    const orders = servicingDebtor
      ? await getOrders('draft', servicingDebtor.id || debtor.id)
      : await getOrders('draft')
    if (!orders) {
      console.error('There was an error while fetching draft orders')
      return null
    }
    const orderData = { employee: rootGetters['user/getEmployee'] }
    if (isServiceAccount) {
      orderData.debtor_id = servicingDebtor.id || debtor.id
    }
    const orderId = orders.length
      ? orders[0].id // we already have a draft order
      : await createDraftOrder(orderData) // no draft order yet, create one
    commit('setOrderId', orderId)
    return orderId
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules: {
    topbar,
    pullrod,
    railrodparts
  }
}
